import React, { useState } from 'react';
import axios from 'axios';
import { Loader2 } from 'lucide-react';

function App() {
  const [videoUrl, setVideoUrl] = useState('');
  const [transcript, setTranscript] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const MAX_RETRIES = 3;
  const RETRY_DELAY = 1000;

  const fetchTranscript = async (retry = 0) => {
    setLoading(true);
    setError('');

    if (!videoUrl) {
      setError('Please enter a YouTube URL');
      setLoading(false);
      return;
    }

    try {
      const API_URL = 'http://localhost:5000/get_transcript';
      const response = await axios.post(API_URL, { video_url: videoUrl });
      setTranscript(response.data.transcript);
    } catch (err) {
      const errorMessage = err.response?.data?.error || 'Failed to fetch transcript';
      
      if (retry < MAX_RETRIES) {
        setTimeout(() => fetchTranscript(retry + 1), RETRY_DELAY);
        setError(`Attempt ${retry + 1}/${MAX_RETRIES}: ${errorMessage}`);
      } else {
        setError(`Failed after ${MAX_RETRIES} attempts: ${errorMessage}`);
      }
    } finally {
      if (retry === MAX_RETRIES - 1 || !error) {
        setLoading(false);
      }
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">YouTube Transcript App</h1>
      <div className="flex gap-2 mb-4">
        <input
          type="text"
          placeholder="Enter YouTube URL"
          value={videoUrl}
          onChange={(e) => setVideoUrl(e.target.value)}
          className="flex-1 p-2 border border-gray-300 rounded"
        />
        <button
          onClick={() => fetchTranscript()}
          disabled={loading}
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 disabled:bg-blue-300 flex items-center gap-2"
        >
          {loading && <Loader2 className="animate-spin h-4 w-4" />}
          {loading ? 'Fetching...' : 'Get Transcript'}
        </button>
      </div>
      
      {error && <p className="text-red-500 mb-4">{error}</p>}
      
      {transcript.length > 0 && (
        <div className="bg-white p-4 rounded shadow">
          <h2 className="text-xl font-semibold mb-2">Transcript</h2>
          <div className="space-y-2">
            {transcript.map((entry, index) => (
              <div key={index} className="flex gap-2">
                <span className="text-gray-500 whitespace-nowrap">{entry.timestamp}</span>
                <p className="flex-1">{entry.text}</p>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default App;